




























































import { addBasePath } from '~/helpers/addBasePath';
import { defineComponent } from '@nuxtjs/composition-api';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  components: {
    SfImage,
  },
  setup() {
    const navigation = [
      { name: 'Adresy sklepów', href: '/i/adresy-sklepow' },
      { name: 'Sprzedaż hurtowa', href: '/i/przedstawiciele-handlowi' },
      { name: 'Info-Karta', href: 'https://info-karta.pl/' },
      { name: 'Kariera', href: '/i/kariera' },
      { name: 'Kontakt', href: '/i/kontakt' },
      { name: 'O nas', href: '/i/o-nas' },
    ];
    return {
      navigation,
      addBasePath,
    };
  },
});
